body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #000 !important;
}

.p5Canvas {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index: -5;
}

.modal-border {
	border-width: 0.31rem;
}

.bg-waitlist {
	background: hsla(60, 82%, 51%, 1);

	background: linear-gradient(00deg, hsla(60, 82%, 51%, 1) 0%, hsla(315, 87%, 62%, 1) 100%);
	
	background: -moz-linear-gradient(00deg, hsla(60, 82%, 51%, 1) 0%, hsla(315, 87%, 62%, 1) 100%);
	
	background: -webkit-linear-gradient(0deg, hsla(60, 82%, 51%, 1) 0%, hsla(315, 87%, 62%, 1) 100%);
	
	filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#E8E81C", endColorstr="#F249C8", GradientType=1 );
		color: '#fff';
		overflow: hidden;
}

.border-waitlist {
	border-color: #F09B85;
}

.bouncing-loader {
	display: flex;
	justify-content: center;
  }
  
  .bouncing-loader > div {
	width: 16px;
	height: 16px;
	margin: 3px 6px;
	border-radius: 50%;
	background-color: #a3a1a1;
	opacity: 1;
	animation: bouncing-loader 0.6s infinite alternate;
  }

  @keyframes bouncing-loader {
	to {
	  opacity: 0.1;
	  transform: translateY(-16px);
	}
  }
  
  .bouncing-loader > div:nth-child(2) {
	animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(3) {
	animation-delay: 0.4s;
  }