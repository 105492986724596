.homepage {
  background-color: #000;
}

.page-item.active .page-link {
  background-color: #302e66;
  border-color: #302e66;
}

.modal-body {
  background-color: aliceblue;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  /* border-top: 1px solid #819efc; */
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
