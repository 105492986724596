@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'SF Pro';
  src: url('SF-Pro-Display-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('SF-Pro-Display-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'SF Pro';
  /* background: linear-gradient(to bottom right, hsl(0 100% 50%), 10%, hsl(325 100% 50%), 75%, hsl(275 50% 50%) );; */
  /* background-color: white; */
  /* width: max-content; */
  /* min-width: fit-content; */
  width: 100%;;
}

html {
  scroll-behavior: smooth;
}

body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.admin-heading {
  font-family: 'Inter', Tahoma, Geneva, Verdana, sans-serif;
}

.dot {
  border-radius: 50%;
  margin-right:  5px;
}

.user-modal-backdrop {
  padding-top: 30vh;
  padding-bottom: 30vh;
  padding-left: 45vh;
  padding-right: 45vh;
}

.subscription-panel {
  background-color: #0D0F11;
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 600px) {
  .subscription-panel {
    max-width: 100%;
    width: 100%;
  }
}

.subscription-panel::-webkit-scrollbar {
  width: 5px;
}

.subscription-panel::-webkit-scrollbar-track {
  background: #141517;
}

.subscription-panel::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.subscription-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.w-95 {
  width: 95%;
}

.data-group {
  border-width: 1px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-left: -17px !important;
}

.roletype-1 {
  background-blend-mode: multiply;
  background-image: linear-gradient(0deg, rgba(20, 21, 23, 0.8), rgba(20, 21, 23, 0.8));
  background-color: #5FFFEC;
  color: #5FFFEC;
}

.roletype-2 {
  background-blend-mode: multiply;
  background-image: linear-gradient(0deg, rgba(20, 21, 23, 0.8), rgba(20, 21, 23, 0.8));
  background-color: #00FF29;
  color: #00FF29;
}

.roletype-3 {
  background-blend-mode: multiply;
  background-image: linear-gradient(0deg, rgba(20, 21, 23, 0.8), rgba(20, 21, 23, 0.8));
  background-color: #DBFF00;
  color: #DBFF00;
}

.roletype-4 {
  background-blend-mode: multiply;
  background-image: linear-gradient(0deg, rgba(20, 21, 23, 0.8), rgba(20, 21, 23, 0.8));
  background-color: #FFFFFF;
  color: white;
}

.admin-modal-shadow {
  box-shadow: 0px 22px 45px 0px #0D0F1159;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.admin-modal {
  /* margin: 10%; */
  position: absolute;
  border: 1px #2C2C2C solid;
  background-color: #141517;
  border-radius: 6px;
  padding: 20px;
  width: 265px;
}

.admin-modal select, .admin-modal input {
  background-color: #141517;
  border: 1px solid #2C2C2C;
  width: 100%;
  height: 32px;
  margin-bottom: 15px;
}

.admin-modal hr {
  border: 1px solid #2C2C2C;
  margin-bottom: 9.75px;
}

.modal-subtitle {
  color: #646568;
}

.cancel-btn {
  background-color: #343434;
  width: 69px;
  height: 30px;
  margin-right: 13px;
}

.refund-btn {
  background-color: #FF4E4E;
  width: 69px;
  height: 30px;
}

.trial-btn {
  background-color: #13AD5A;
  width: 90px;
  height: 30px;
}

.cancel-sub-btn {
  background-color: #FF4E4E;
  width: 90px;
  height: 30px;
}

.cancel-modal {
  width: 25% !important;
}

.navbar-container {
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 2%;
  background-color: #1A202C;
}

.navbar-inner-container {
  padding-top: 2%;
}